import React from "react"
import { lighten } from "polished"
import styled from "styled-components"

import { bt_darkblue } from "../components/color"
import Layout, { Section, Spacer } from "../components/layout"
import { SectionHeading, SectionSubHeading } from "../components/typography"
import SEO from "../components/seo"

const ArticleLink = styled.a`
  &:link,
  &:visited {
    text-decoration: none;
    color: ${props => (props.light ? "white" : bt_darkblue)};
  }
  &:hover {
    color: ${props => (props.light ? "white" : lighten(0.2, bt_darkblue))};
  }
`

export default function Articles() {
  return (
    <Layout IsDarkTheme>
      <SEO
        title="Articles"
        description="Articles and academic papers related to the BlueTrace protocol"
      />
      <Section>
        <div className="container">
          <SectionHeading>Articles</SectionHeading>
          <SectionSubHeading>News Outlets</SectionSubHeading>
          <ArticleLink
            href="https://hbr.org/2020/04/how-digital-contact-tracing-slowed-covid-19-in-east-asia"
            rel="noopener noreferrer nofollow"
          >
            <strong>
              <u>How Digital Contact Tracing Slowed Covid-19 in East Asia</u>{" "}
              (April 15, 2020)
            </strong>
            <p>
              An article that looks into the effectiveness of contact tracing
              technology in East Asian countries. It discusses the adoption
              limitations of this technology in Western democracies as they have
              different expectations with regard to privacy and civil liberties.
            </p>
          </ArticleLink>

          <ArticleLink
            href="https://www.abc.net.au/news/2020-04-14/coronavirus-app-government-wants-australians-to-download/12148210"
            rel="noopener noreferrer nofollow"
          >
            <strong>
              <u>
                ABC: Coronavirus lockdowns could end in months if Australians
                are willing to have their movements monitored
              </u>{" "}
              (April 14, 2020)
            </strong>
            <p>
              An article that highlights how the Australian government is
              developing a mobile application similar to TraceTogether to help
              with contact tracing in Australia.
            </p>
          </ArticleLink>

          <ArticleLink
            href="https://www.newshub.co.nz/home/politics/2020/04/singapore-s-covid-19-contact-tracing-tech-tracetogether-catches-jacinda-ardern-s-eye-to-fight-coronavirus.html"
            rel="noopener noreferrer nofollow"
          >
            <strong>
              <u>
                Singapore's COVID-19 contact tracing tech TraceTogether catches
                Jacinda Ardern's eye to fight coronavirus
              </u>{" "}
              (April 9, 2020)
            </strong>
            <p>
              An article citing New Zealand PM Jacinda Ardern's interest in
              adapting TraceTogether, which will be a subject of a phone call
              with Singapore PM Lee Hsien Loong. Ardern is quoted emphasising
              the importance of having people working on contact tracing, to
              complement such apps.
            </p>
          </ArticleLink>

          <ArticleLink
            href="https://www.ft.com/content/d42acff2-b0b5-400b-b38f-ec621d4efd95"
            rel="noopener noreferrer nofollow"
          >
            <strong>
              <u>
                Financial Times: US and Europe race to develop ‘contact tracing’
                apps
              </u>{" "}
              (April 3, 2020)
            </strong>
            <p>
              An article describing the efforts of various countries to build
              apps similar to TraceTogether. It also highlights the privacy
              concerns of such an app and discusses the benefits and drawbacks
              of using GPS tracking instead of Bluetooth.
            </p>
          </ArticleLink>

          <ArticleLink
            href="https://www.straitstimes.com/singapore/about-one-million-people-have-downloaded-the-tracetogether-app-but-more-need-to-do-so-for?utm_source=STSmartphone&amp;utm_medium=share&amp;utm_term=2020-04-02+08%3A26%3A25"
            rel="noopener noreferrer nofollow"
          >
            <strong>
              <u>
                The Straits Times: About 1 million people have downloaded
                TraceTogether app, but more need to do so for it to be
                effective: Lawrence Wong
              </u>{" "}
              (April 1, 2020)
            </strong>
            <p>
              An article explaining the need for widespread adoption of
              TraceTogether in order for it to be more effective in supporting
              contact tracing.
            </p>
          </ArticleLink>

          <ArticleLink
            href="https://www.straitstimes.com/forum/forum-decision-taken-not-to-electronically-tag-people"
            rel="noopener noreferrer nofollow"
          >
            <strong>
              <u>
                The Straits Times Forum: Decision taken not to electronically
                tag people
              </u>{" "}
              (March 31, 2020)
            </strong>

            <p>
              An explanation on why TraceTogether will not be used to track
              people serving stay-home notices and leave of absences.
            </p>
          </ArticleLink>

          <ArticleLink
            href="https://www.nytimes.com/reuters/2020/03/30/technology/30reuters-health-coronavirus-germany-tech.html"
            rel="noopener noreferrer nofollow"
          >
            <strong>
              <u>
                The New York Times: Germany Aims to Launch Singapore-Style
                Coronavirus App in Weeks
              </u>{" "}
              (March 30, 2020)
            </strong>
            <p>
              An article that outlines Germany’s plans to emulate TraceTogether
              through an app that also performs decentralised encounter logging
              with short-lived identifiers and calibrated distance estimates, as
              it becomes increasingly clear that contact tracing is important to
              combat the spread of the virus.
            </p>
          </ArticleLink>

          <ArticleLink
            href="https://www.straitstimes.com/multimedia/a-guide-to-singapores-covid-19-contact-tracing-system"
            rel="noopener noreferrer nofollow"
          >
            <strong>
              <u>
                The Straits Times: A guide to Singapore's Covid-19
                contact-tracing system
              </u>{" "}
              (March 28, 2020)
            </strong>
            <p>
              A video that explains Singapore’s contact tracing system. It
              highlights that TraceTogether will only complement current contact
              tracing efforts, and is not a silver bullet or a replacement for a
              team of contact tracers working with a public health authority.
            </p>
          </ArticleLink>

          <ArticleLink
            href="https://www.gov.sg/article/help-speed-up-contact-tracing-with-tracetogether"
            rel="noopener noreferrer nofollow"
          >
            <strong>
              <u>Gov.sg: Help speed up contact tracing with TraceTogether</u>{" "}
              (March 21, 2020)
            </strong>
            <p>
              An article that explains how TraceTogether supplements the
              Singapore Ministry of Health's current contact tracing system. It
              is highly unlikely that we are able to remember every person we
              came into contact with for the last 14 days, especially if these
              people are strangers, like the person we sat next to on the train,
              or the waiter that served our food. In these cases, the app will
              facilitate the contact tracing process, enabling contact tracers
              to better identify those who were close contacts of COVID-19
              cases. A{" "}
              <a
                href="https://www.facebook.com/ChannelNewsAsia/videos/250003462832085/"
                rel="noopener noreferrer nofollow"
              >
                <u>press conference</u>{" "}
              </a>
              by SMS Janil Puthucheary further elaborates on this.
            </p>
          </ArticleLink>

          <ArticleLink
            href="https://www.nytimes.com/reuters/2020/03/20/technology/20reuters-health-coronavirus-singapore-technology.html"
            rel="noopener noreferrer nofollow"
          >
            <strong>
              <u>
                The New York Times: Singapore Launches Contact Tracing Mobile
                App to Track Coronavirus Infections
              </u>{" "}
              (March 20, 2020)
            </strong>
            <p>An article that explains how TraceTogether works.</p>
          </ArticleLink>

          <ArticleLink
            href="https://www.bbc.com/news/world-asia-51866102"
            rel="noopener noreferrer nofollow"
          >
            <strong>
              <u>
                BBC: The detectives racing to contain the virus in Singapore
              </u>{" "}
              (March 19, 2020)
            </strong>
            <p>
              An article that explains Singapore’s contact tracing efforts with
              real examples. It also explains why contact tracing is a necessary
              tool in flattening the curve.
            </p>
          </ArticleLink>

          <Spacer height="2rem" />
          <SectionSubHeading>Academic Papers</SectionSubHeading>

          <ArticleLink
            href="https://science.sciencemag.org/content/early/2020/03/30/science.abb6936"
            rel="noopener noreferrer nofollow"
          >
            <strong>
              <u>
                Quantifying SARS-CoV-2 transmission suggests epidemic control
                with digital contact tracing
              </u>{" "}
              (March 31, 2020)
            </strong>

            <p>
              A research article which advocates for greater use of contact
              tracing apps to detect and isolate coronavirus cases, given
              evidence of asymptomatic and pre-symptomatic transmission.
            </p>
          </ArticleLink>

          <ArticleLink
            href="https://arxiv.org/pdf/2003.11511.pdf"
            rel="noopener noreferrer nofollow"
          >
            <strong>
              <u>
                Contact Tracing Mobile Apps for COVID-19: Privacy Considerations
                and Related Trade-offs
              </u>{" "}
              (March 30, 2020)
            </strong>
            <p>
              An academic paper that discusses various privacy models
              underpinning contact tracing apps. It also highlights the unique
              sociopolitical context in Singapore that has resulted in broad
              acceptance by its citizens.
            </p>
          </ArticleLink>

          <ArticleLink
            href="https://arxiv.org/pdf/2003.10222.pdf"
            rel="noopener noreferrer nofollow"
          >
            <strong>
              <u>Proximity: a recipe to break the outbreak</u> (March 23, 2020)
            </strong>
            <p>
              An academic paper that talks about a proposed smartphone
              application similar to TraceTogether. It acknowledges that such an
              app is vital to contain an outbreak and Bluetooth is the most
              suitable technology.
            </p>
          </ArticleLink>
        </div>
      </Section>
    </Layout>
  )
}
